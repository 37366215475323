@import url('https://fonts.googleapis.com/css2?family=Fira+Sans+Condensed:wght@400;500;600;700&family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@500;600&family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Audiowide&display=swap');

.footer-column {
  font-size: 20px; /* Adjust the font size as needed */
  border-right: 3px solid #ccc; /* Add a right border to create a line between columns */
}

.footer-column:last-child {
  border-right: none; /* Remove the border from the last column to avoid extra line */
}

.fontlogo {
  font-family: 'Audiowide', sans-serif;
}

.footer-link {
  font-size: 18px; /* Adjust the font size for the email link */
  text-decoration: none;
  color: white;
}
.footer-separator{
  padding-left: 50px;
}
.footer-first {
  padding-left: 50px;
}
.footer-button {
  border: 2px solid #007BFF; /* Blue border color */
  border-radius: 20px;
  padding: 5px 18px; 
  background-color: transparent; 
  color: black; 
  text-decoration: none;
  cursor: pointer; 
  display: inline-block; 
  margin-bottom: 30px;
}

.footer-button:hover {
  background-color: #194cf3; /* Change background color on hover */
  color: #fff; /* Change text color on hover */
}

.navbar {
  font-family: 'League Spartan', sans-serif;
}

.navbar>.container, .navbar>.container-fluid {
    display: flex;
}

.navbar-scrolled {
    background-color: rgb(126, 116, 116);
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
    font-size: 20px;
    font-weight: 600;
    padding-right: 300px;
    padding-top: 20px;

}

.top-container {
  position: relative; 
    flex-direction:column;
    justify-content: flex-start;
    align-items: center;
    color: white;
    display: flex;
    align-items: center;
    margin: 0;
    width: 100vw;
    height: 80vh;
    text-shadow: 8px 8px 10px #0000008c;
}
.background-imagemain {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 80vh; /* Adjust the height to your preference */
  /* overflow: hidden; */
}

.background-imagemain img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* filter: brightness(75%); */
}

.homeone {
    text-align: center;
    position: relative; 
}

.steshmain{
  font-family: 'Audiowide', sans-serif;
  color: #d0d0d1;
  font-size: 45px;
}

.toptext {
    margin: 20vh 1rem;
    padding-left: 40px;
    padding-right: 40px;
    position: relative; 
    z-index: 1; 
    text-align: center;
    
}

.top-container h1 {
    font-size: 2rem; 
    padding-bottom: 30px;
}
  
.smalltop {
    color: white;
    font-weight: 500;
    font-size: 30px;
    font-family: 'Poppins', sans-serif;
   
}

.arrow-container {
    width: 40px;
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation: bounce 2s infinite;
    margin: 0 auto;
}
  
  .arrow-stick {
    width: 10px; /* Width of the stick */
    height: 50px; /* Height of the stick */
    background-color: #ffffff; /* Stick color */
}
  
  .arrow-down {
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 20px solid #ffffff; /* Arrowhead color */
}
  
  @keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(10px); /* Adjust the distance it moves */
    }
}
  
.steshnormal{
  font-family: 'Audiowide', sans-serif;
}
.steshhomemiddle {
  padding-bottom: 30px;
  padding-top: 30px;
}

.middle-container {
    background-color: #343a40;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
    background: linear-gradient(to bottom, #023872, #1654abc9);
    text-align: center;
  
}

.middle-text{
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 1s, transform 1s;
    max-width: 800px; /* Set a maximum width for the text box */
    margin: 0 auto; 
    font-size: 20px;
}

@keyframes fade-in {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
}

  .middle-text.in-viewport {
    animation: fade-in 2s forwards;
}
.homegetcontact {
  background-color:  #01346c; 
  padding: 40px; 
  text-align: center;
  color: white;
}

.contact-banner {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.banner-text h2 {
  font-size: 24px; 
  margin: 0;
}

.banner-button button {
  background-color: #000000; 
  color: white;
  padding: 10px 20px;
  border: 1px white solid;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px; 
}

.banner-button button:hover {
  background-color: #ffffff; 
  color: #000000;
  border: 1px black solid;
}

.quote-section {
  text-align: center;
  padding: 20px; /* Adjust the padding as needed */
}

.quote-container {
  max-width: 600px; /* Adjust the max-width as needed */
  margin: 0 auto; /* Center the container horizontally */
  padding-top: 60px;
  padding-bottom: 60px;
}

.quote {
  font-style: italic;
  color: #333; /* Adjust the color as needed */
  font-size: 24px;
  font-weight: bolder;
}

.quote-author {
  margin-top: 10px; /* Adjust the margin as needed */
  font-weight: bold;
  color: #666; /* Adjust the color as needed */
}

.custompadding {
  
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.customtest {
  padding-left: 10px;
}

.middle-end-container {
   background: linear-gradient(to bottom, #023872, #1654abc9);
    text-align: center;

}

.services-headtext {
  color: white;
}
.career-toptext {
  padding-left: 30px;
  padding-right: 30px;
}
.middle-end-header{
    color: white;
    font-size: 2rem;
    margin: 0 auto;
    padding-bottom: 50px;
    padding-top: 65px;
}

.card-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    justify-items: center;
    padding: 30px 60px;
    opacity: 1; 
    /* change to 0 when working on fade */
    transform: translateY(20px);
    transition: opacity 1s, transform 1s
}
/* .middle-text{
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 1s, transform 1s;
} */

@keyframes fade-in {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
}

  .card-container.in-viewport {
    animation: fade-in 2s forwards;
      opacity: 1;
}


.card {
    text-align: center;
    padding: 20px;
    border: 1px solid #ccc;
}

.card-header{
    margin-top: 10px;
}
.card-img {
    background-color: #ccc; /* Card image styles */
    width: 10px;
    height: 250px;
    margin: 0 auto;
 
}
.card-one {
    background-image: url('https://images.pexels.com/photos/4960396/pexels-photo-4960396.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1');
  background-size: cover; /* Adjust the background size to fit your card */
  background-position: center;
 
}
.card-two {
    background-image:url('https://images.pexels.com/photos/2760241/pexels-photo-2760241.jpeg?auto=compress&cs=tinysrgb&w=400');
    background-size: cover; /* Adjust the background size to fit your card */
    background-position: center; 
 
}
  
.card-three {
    background-image:url('https://images.pexels.com/photos/6476255/pexels-photo-6476255.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1');
    background-size: cover; /* Adjust the background size to fit your card */
    background-position: center; 
}



  @media screen and (max-width: 768px) {
    .card-container {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media screen and (max-width: 480px) {
    .card-container {
      grid-template-columns: 1fr;
    }
  }
  .contactus {
    height: 100vh;
  }
  
  .contactheader{
    margin-top: 20px;
  }

  .contacttext {
    color: white;
    display: flex;
    padding: 20px 60px;
  }
  .form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  form {
    text-align: center;
  }



@media (max-width: 768px) {
    .top-container h1 {
      font-size: 2.2rem; /* Adjust the font size for h1 on mobile */
      padding-bottom: 40px;
    }
  
    .smalltop {
      font-size: 0.9rem; /* Adjust the font size for smalltop text on mobile */
    }
  }

/* services page */
  .service-header {
    padding-top: 80px;
  }
  .straticons {
    color: rgb(48, 48, 212);
  }
.services-container{
    padding-bottom: 100px;
}

/* career */


.career-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.careeruscon{
    text-align: center;
}

.careerusheader{
    font-family: 'Roboto', sans-serif; 
    padding-bottom: 20px;
}

.careerustext{
    font-size: 20px;
    font-weight: 200;
}

.careerapp{
    padding-top: 20px;
}

/* aboutus */


.abouttop {
  position: relative;
  text-align: center;
  color: white;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 30vh; /* Adjust the height to your preference */
  overflow: hidden;
}

.background-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(50%);
}

.abouttext {
  position: relative;
  z-index: 1;
  font-family: 'Fira Sans Condensed', sans-serif;
  padding-top: 20vh; /* Adjust the padding based on the height of the background image */
}

.aboutbig {
  font-weight: 700;
  font-size: 50px;
  padding-bottom: 25px;
}

.aboutsmall {
  font-weight: 500;
  font-size: 25px;
}


.careerentireflex{
  text-align:center;
  font-family: 'Roboto', sans-serif;
   justify-content: center;
   vertical-align: middle;
   display: flex;
   padding-top: 50px;
   padding-bottom: 20px;
}

.careerentirecon{
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 700px;
  text-align: left;
}

.careerentireheader{
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 20px;
  
}

.careerentiretext{
  font-size: 20px;
  padding-bottom: 70px;
  text-align: left;
}

@media screen and (max-width: 600px) {
  .careerentireflex {
      justify-content: left; 
      padding-left: 20px;/* Center content on smaller screens */
  }
  .careerentireheader {
    justify-content: left; 
    padding-left: 20px;/* Center content on smaller screens */
}
}

.kareer-background {
  position: relative;
  height: 100vh;
  overflow: hidden;
}

.kareer-background img {
  width: 100%;
  height: auto;
  object-fit: cover;
  opacity: 60%;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Adjust the overlay color and transparency as needed */
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.kareerusheader {
  font-size: 2em;
  margin-bottom: 10px;
  color: #fff; /* Adjust text color as needed */
  font-weight: 500;
}

.kareerustext {
  font-size: 1.2em;
  margin-bottom: 20px;
  color: #fff; /* Adjust text color as needed */
}

.kareerapp {
  font-size: 1.2em;
  color: #fff; /* Adjust text color as needed */
}

.kareer-middle-text{
  background-color: #ffffff;
  padding-top: 80px;
  padding-bottom: 80px;
  color: black;
  font-size: 20px;
  justify-content: center;
  text-align: center; 
}
.footer-firstone {
  max-width: 800px; /* Set a maximum width for the text */
  margin: 0 auto; /* Center the text horizontally */
  padding: 0 30px; 
  
}
.kareer-bottom-text{
  background-color: #3e68f2;
  padding-top: 150px;
  padding-bottom: 150px;
  color: white;
}

.show-vacancies-btn {
  display: inline-block;
  padding: 10px 20px;
  background: #3498db;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  font-size: 1.2em;
  margin-top: 10px; /* Adjust margin as needed */
}

.kareermiddle-header {
  font-size: 40px;
}

.kareer-stesh-h1 {
  justify-content: center;

}
.kareer-main-head {
  padding-top: 30px;
}

/* getintouch */
.formlabel {
  padding-top: 10px;
  padding-bottom: 10px;
}

.form-group input {
  /* border: none;
  border-bottom: 1px solid rgb(255, 255, 255); Add a white underline */
  background-color: transparent;
  color: white;
  padding: 5px;
}
.form-group input:focus {
  border-bottom: 2px solid white; /* Increase the thickness on focus */
}
.form-control {
  border: none;
  border-bottom: 1px solid black; /* Line under the input */
  border-radius: 0; /* Remove any border-radius */
  outline: none; /* Remove the default focus outline */
  transition: border-color 0.3s ease; /* Add a smooth transition for the border color */
  width: 100%;
}

label {
  display: block;
  margin-bottom: 5px;
}


.form-control:focus {
  border-color: gray; /* Change the border color on focus */
}

.contact {
  padding-top: 100px;
}

.contact-email {
  color: white;
  text-decoration: none;
  font-size: 35px;
  font-weight: 300;

}
.contacttoptext {
  font-size: 45px;
  font-weight: 500;
}

.contactemailform{
  margin-top: 50px;
  background-color: #3e68f2;
  padding-top: 150px;
  padding-bottom: 150px;
  color: white;
}

.messagesubmit{
  background-color: #000000; 
  color: white;
  padding: 10px 20px;
  border: 1px white solid;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px; 
  margin-top: 20px;
}

.messagesubmit:hover {
  background-color: #ffffff; 
  color: #000000;
  border: 1px black solid;
}

.contacttouch {
  font-size: 60px;
}

.footer{
    background-color: black;
    color: rgb(226, 218, 218);
    padding-top: 40px;
    padding-bottom: 60px;
    height: 45vh;
 
}
.bottomlinks{
    color: white;
    text-decoration: none;
    padding: 0;
    margin:0;
}

.bottomlinks:hover{
    color: rgb(81, 76, 230);

}

@media (max-width: 767px) {
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Adjust the overlay color and transparency as needed */
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column; /* Stack text in a column for smaller screens */
  }
  .kareerusheader {
    margin-top: 180px;
    margin-bottom: 10px; /* Adjust margin as needed for better spacing on mobile */
  }
 
  .kareerustext,
  .kareerapp {
    margin-top: 50px;
    margin-bottom: 10px; /* Adjust margin as needed for better spacing on mobile */
    padding-left: 20px;
    padding-right: 20px;
  }

  .show-vacancies-btn {
    margin-top: 20px; /* Adjust margin as needed for better spacing on mobile */
  }
}

@media (max-width: 767px) {
  .footer-column {
    align-items: center;
    text-align: center;
    color: white;
  }

  .contactemailform {
    padding-top: 80px;
    padding-bottom: 80px;
    padding-right: 30px;
    color: white;
  }

  .contacttouch {
    font-size: 40px;
    color: white;
  }

  .formcontact {
    max-width: 100%; /* Adjust the width for better responsiveness */
    margin: 0 auto;
    color: white;
  }

  .form-group {
    width: 100%; /* Make form inputs full-width on smaller screens */
    margin-bottom: 20px;
    color: white;
  }
  .form-group input {
    /* border: none;
    border-bottom: 1px solid rgb(255, 255, 255); Add a white underline */
    background-color: transparent;
    color: white;
    padding: 5px;
  }
  .form-group input:focus {
    border-bottom: 2px solid white; /* Increase the thickness on focus */
  }

.messagesubmit{
  background-color: #000000; 
  color: white;
  padding: 10px 20px;
  border: 1px white solid;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px; 
}

.messagesubmit:hover {
  background-color: #ffffff; 
  color: #000000;
  border: 1px black solid;
}
  .firstnamepad{
    padding-top: 40px;
  }
}